
import moment from 'moment';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import ContentLayout from '~/layouts/content-layout.vue';

@Component({
  components: {ContentLayout},
})
export default class App extends Vue {
  async created() {
    this.$store.state.app.showNavigation = this.$vuetify.breakpoint.smAndUp;
    moment.locale(this.$i18n.locale);
  }
}
