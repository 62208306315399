import {Module} from 'vuex';
import {RootState} from '~/store';

export interface ContentState {
  screens: any[],
  activeScreenIndex: number,
}

export const contentStore: Module<ContentState, RootState> = {
  namespaced: true,
  state: {
    screens: [],
    activeScreenIndex: 0,
  },
  mutations: {
    updateActiveScreen(state, screenIndex: number) {
      state.activeScreenIndex = screenIndex;
    },
  },
  actions: {
    registerScreen({state}, screen) {
      state.screens.push(screen);
    },
  },
};
