import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {path: '/', name: 'content', component: () => import(/* webpackChunkName: "content" */ '~/pages/content.page.vue')},
  {path: '/imprint', name: 'imprint', component: () => import(/* webpackChunkName: "imprint" */ '~/pages/imprint.page.vue')},

  // 404 page needs to be last in routes array
  {path: '*', name: 'not-found', component: () => import( /* webpackChunkName: "404.page" */ './pages/status/404.page.vue')},
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
