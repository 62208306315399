import Vue from 'vue';
import Vuex from 'vuex';
import {appStore} from '~/store/app.store';
import {contentStore} from '~/store/content.store';
import {resumeStore} from '~/store/resume.store';

Vue.use(Vuex);
export type RootState = any;

export const store = new Vuex.Store<RootState>({
  plugins: [],
  modules: {
    app: appStore,
    resume: resumeStore,
    content: contentStore,
  },
  state: {},
  mutations: {},
  actions: {},
});
