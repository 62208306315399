
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
export default class UserHeader extends Vue {
  onNavIconClick() {
    this.$store.dispatch('app/toggleNavigation');
  }

  get logoImgUrl() {
    return this.$store.state.app.darkMode ? '/assets/img/logo/logo_white.png' : '/assets/img/logo/logo_black.png';
  }

  get logoTextUrl() {
    return this.$store.state.app.darkMode ? '/assets/img/logo/text_white.png' : '/assets/img/logo/text_black.png';
  }
}
