
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import SocialContacts from '~/components/social-contacts.vue';

@Component({
  components: {SocialContacts},
})
export default class ContentNavigation extends Vue {
  get app() {
    return this.$store.state.app;
  }

  get content() {
    return this.$store.state.content;
  }

  get resume() {
    return this.$store.state.resume;
  }

  setLanguage(locale) {
    this.$i18n.locale = locale;
    // setLocale(locale);
  }

  onChangeColorClick() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    // toggleDarkTheme(this.$vuetify.theme.dark);
  }

  async onItemClick(id) {
    await Promise.all([
      this.$vuetify.goTo(id),
      this.$store.dispatch('app/setNavigation', false),
    ]);
  }

  async onLogoClick() {
    await Promise.all([
      this.$vuetify.goTo('#intro_screen'),
      this.$store.dispatch('app/setNavigation', false),
    ]);
  }

  get navigationItems() {
    return [
      {id: '#about_screen', text: this.$t('navigation.about')},
      {id: '#experience_screen', text: this.$t('navigation.experience')},
      {id: '#skills_screen', text: this.$t('navigation.skills')},
      {id: '#portfolio_screen', text: this.$t('navigation.portfolio')},
      {id: '#clients_screen', text: this.$t('navigation.clients')},
      {id: '#contact_screen', text: this.$t('navigation.contact')},
    ];
  }
}
