export const en = {
  navigation: {
    about: 'About Me',
    experience: 'Experience',
    skills: 'Skills',
    portfolio: 'Portfolio',
    clients: 'Clients',
    contact: 'Contact',
  },
  action: {
    send: 'Send',
  },
  label: {
    location: 'Location',
    address: 'Address',
    email: 'Email',
    phone: 'Phone',
    skype: 'Skype',
    goLive: 'Went live',
    tags: 'Tags',
    client: 'Client',
  },
  screen: {
    about: {
      who_i_am: {
        title: 'Who I am',
        text: `Hi, my name is Sascha and I am a Freelance Software Engineer from the Rhein-Main Region.<br/>
I'm {age} years old and work in the software development industry for over {experienceInYears} years now.<br/>

I the course of the digital transformation of your business processes, I can support you from the conceptional draft to the final product.
Frontend- and Backend-Development from one source.<br/>

Smarts Software Architectures, modern Frameworks, Clean Code, Test Driven Development and Domain Driven Design.<br/>
I'm familiar with the practices of Agile Software Development and a fan of Extreme Programming.<br/>
Unit-, Integration- oder Acceptance-Tests will be covered as required. 
CI Pipelines make sure, all tests pass before the Software will be deployed.<br/>`,
      },
      what_i_do: {
        headline: 'What I do',
        text: `In easy words: I develop Web Applications. Software you can use with the Browser of your PCs or mobile device.<br/>
          This way, I can be a strong partner for the <b>digital Transformation</b> or your company. 
          I work agile and transparent to realise your Vision. I'll provide access to my work in short intervals, so that you can give feedback right away.
          With your Feedback and the priorization of the upcoming tasks, you're in control of your vision and activly participating to the creation of the final product.`,
      },
      how_i_do_it: {
        headline: 'How I do it',
        text: `
<p>Da ich aktuell im nicht sonderlich ausgebucht bin, beträgt mein Stundensatz aktuell 60€ sofern ich keine Rechte abtreten muss.</p>
<p>Pauschale... können wir gerne machen, doche es wird teurer...</p>
<p>Rechte abtreten... können wir gerne machen, doche es wird teurer...</p>`,
      },
      what_i_can_do_for_you: {
        headline: 'What I can do for you',
        services: [
          {
            title: 'Frontend Development',
            text: 'Ich habe mich spezialisiert auf moderne web-applications und arbeite mit den neusten Technologien daran,' +
              'ihre Ideen in die Realität zu überführen. Dabei setze ich hauptsächlich auf javascript bzw typescript.' +
              'Im Backend arbeite ich primär mit Node.js und NestJS. Im Frontend baue ich auf Vue.Js und VuetifyJs.' +
              'Damit schaffe ich Web-Applikationen die auch mobile ansprechend sind und sich einfach bedienen lassen.',
          },
          {
            title: 'Backend Development',
            text: 'Sie brauchen keine Web-Applikation sodern nur eine API, die ihre bestehenden Services erweitern soll?' +
              'Kein Problem, sagen sie mir welche funktionen sie benötigen und ich liefere ihnen eine REST API',
          },
          {
            title: 'Dev Ops',
            text: 'Brauchen sie unterstützen bei ihrem',
          },
          {
            title: 'Administration',
            text: 'lorem...',
          },
          {
            title: 'Design',
            text: 'Ich kann zwar mit der Adobe Creative Suite gut umgehen, doch ich bin kein geborener Designer. ' +
              'Sollten ihre design-technischen Anforderungen meinen kreativen Horizont überschreiten, ' +
              'habe gute Kontakte zu erfahrenen Designer die ihren Anforderungen gerecht werden.',
          },
          {
            title: 'Consulting / Support',
            text: `Brauchen sie Hilfe eines erfahrenen Software Engineers in ihrem Projekt?
              Sei es in form von Beratung, Konzeption oder Umsetzung, freue ich mich darauf sie tatkräftig zu unterstützen.`,
          },
        ],
      },
    },
    experience: {
      title: 'Where I\'ve been',
      timeline: [
        {
          color: '#fa0',
          year: 'since 2009',
          headline: 'shabbtech',
          position: 'Freelancer',
          text: 'Project excerpt:\\n\' +\n' +
            '              \' 2014 - wedding.shabbtech.com Wedding presentfor my sister and brother-in-law. user: ”vistor” password: ”codeprobe”\\n\' +\n' +
            '              \' 2014 - scn-ffm.de Web presence creation based on Drupal.\\n\' +\n' +
            '              \' 2012 - Ignition Groupe GmbH See above\\n\' +\n' +
            '              \' 2011 - tcgwwalldorf.de Added custom functionality to an CMS based on websitebaker.\\n\' +\n' +
            '              \' 2010 - andrelanger.com Web presence creation with CMS functionality written in Flex/ActionScript.\\n\' +\n' +
            '              \' 2009 - Tarmac Aviation GmbH created animated 3D clips / upgraded a php based event management tool.',
        },
        {
          color: '#fa0',
          year: '2015 - 2019',
          headline: 'Factset Digital Solutions GmbH',
          position: 'Senior Developer',
          text: 'Application Framework:\n' +
            'Concept, Creation and Coordination of a new approach to implement upcoming projects.\n' +
            'This includes:\n' +
            ' Creation of a vagrant/homestead based development vm.\n' +
            ' Switch from a legacy zend 1 based structure to a standardized one based on symfony 4.\n' +
            ' Creation of multiple php libraries and symfony bundles which can be used by projects.\n' +
            ' Setup and scaffolding of webpack and other tools to support front-end development.\n' +
            ' Creation of yeoman generators to easily generate a starting point for projects and/or libraries.\n' +
            ' Creation of typescript based libraries: Logging, Data-Retrieval (requests/subscriptions), charting.\n' +
            ' Endpoint Reference:\n' +
            'Concept, Creation and Coordination of a new approach to implement projects.\n' +
            'This includes creation and coordination of a:\n' +
            ' angular 2+ based client side app to browse api’s (swagger based),\n' +
            'explore endpoints, read their documentation and perform requests.\n' +
            ' express based backend application that serves the need of the client app.\n' +
            ' Authentication Token Server:\n' +
            'Creation of an nodejs application to generate tokens for authentication proposes.\n' +
            ' Commerzbank infobroker 2.0:\n' +
            'Development of the Commerzbank Infobroker 2 application.',
        },
        {
          color: '#fa0',
          year: '2011 - 2013',
          headline: 'Ignition Groupe GmbH',
          position: 'Frontend Developer',
          text: 'Worked in a small front-end developer team on internal CMS functionalities and the customer facing\n' +
            'appearance of all Chevrolet Europe web presences. View testimonial\n' +
            'Project excerpt:\n' +
            ' Dealer Locator\n' +
            'Tech used: JavaScript (OOP), Google Maps API, HTML, CSS, SVN\n' +
            ' AccessoryManager 2.0\n' +
            'Tech used: JavaScript (OOP/MVC), HTML, SASS, CSS, Git, XSLT\n' +
            ' WebForms 2.0\n' +
            'Tech used: JavaScript (OOP/MVC), HTML5, SASS, Compass, CSS, XSLT, Git, SCRUM\n' +
            ' OffersDB 2.0\n' +
            'Tech used: JavaScript (OOP/MVC), HTML5, SASS, Compass, CSS, Grunt, Git, SCRUM\n' +
            ' Responsive Web Design\n' +
            'Concept and creation of the html-, css- and js-structure to support the new responsive webdesign approach.\n' +
            'This includes the creation of an internal JavaScript framework, a custom floating grid system written in\n' +
            'SASS and the whole HTML5 structure. Built with Grunt and collaborated over github while following SCRUM\n' +
            'methodology',
        },
        {
          color: '#fa0',
          year: '2011',
          headline: 'CASED',
          position: 'Student Research Assistant',
          text: 'Implemented enhancements for a network propagation simulation tool and performed evaluations on\n' +
            'different scenarios. In particular I did research and evaluation on various human transmitted diseases\n' +
            'based on an extensive dataset from South America.\n',
        },
        {
          color: '#fa0',
          year: '2008 - 2011',
          headline: 'Mks-InfoFabrik',
          position: 'Software Developer (in part time)',
          text: 'Worked as supportive developer solo on several projects. View testimonial.\n' +
            'Project excerpt:\n' +
            ' Development of VBA functions for an school management system.\n' +
            ' Development of an OpenReports(Web)-plugin for iReports(Java).\n' +
            ' Development of an NetBeans-plugin for OpenReports.\n' +
            ' Concept and creation of an CMS using PHP, MySQL, JavaScript, CSS, XHTML.',
        },
      ],
    },
    skills: {
      title: 'What I know',
      text: `Wer mit Technik arbeitet, lernt nie aus. Das gilt insbesondere für die Software Entwicklung.
        Auch wenn ich in der Vergangenheit schon viel mit anderen Sprachen und Frameworks gearbeitet habe,
        möchte ich hier die Techniken und Werkzeuge vorstellen welche in meinem Fokus sind oder sich dahin bewegen.`,
      techstack: {
        javascript: 'js eng',
        typescript: '',
        nodejs: '',
        html5: '',
        vuejs: '',
        angular: '',
        php: '',
        docker: '',
        jenkins: '',
        nginx: '',
        amazonwebservices: '',
        bitbucket: '',
        github: '',
        babel: '',
        css3: '',
        git: '',
        sass: '',
        mongodb: '',
        webpack: '',
        yarn: '',
        jest: 'lorem  ipsum dolor mit',
        composer: 'lorem  ipsum dolor mit',
        symfony: 'lorem  ipsum dolor mit',
        laravel: 'lorem  ipsum dolor mit',
        java: 'lorem  ipsum dolor mit',
        kotlin: 'lorem  ipsum dolor mit',
        apache: 'lorem  ipsum dolor mit',
        digitalocean: 'lorem  ipsum dolor mit',
      },
    },
    portfolio: {
      title: 'What I\'ve done',
      projects: [
        {
          src: '/assets/img/projects/shabbtech.jpg',
          title: 'shabbtech.com',
          text: 'Meine Seite auf der ich mich als Professioneller Fullstack Engineer vorstelle.',
          start: '01.01.2019',
          client: 'CAT Europe GmbH',
          categories: ['typescript', 'PWA', 'Vue.js', 'Vuetify', 'Node.js', 'Nest.js', 'MySQL', 'MongoDB', 'Docker', 'AWS', 'REST API'],
        },
        {
          src: '/assets/img/projects/ltms.jpg',
          title: 'LTMS',
          text: 'Das Lunatech Management System ist eine Web-Applikation, die zum verwalten von Flugschulen, Flugschülern und Lerndokumenten für angehende Flugschüler genutzt wird.',
          start: '01.01.2019',
          client: 'CAT Europe GmbH',
          categories: ['typescript', 'PWA', 'Vue.js', 'Vuetify', 'Node.js', 'Nest.js', 'MySQL', 'MongoDB', 'Docker', 'AWS', 'REST API'],
        },
        {
          src: '/assets/img/projects/scn.jpg',
          title: 'SCN e.V. - Vereins Applikation',
          text: 'Der Webauftritt des Sport Clubs der Nestlé AG in Frankfurt. Die Web-Applikation mit wird genutzt um Informationen über die einzelnen Sportgruppe zu erhalten und zu pflegen.',
          start: '01.04.2016',
          client: 'SCN e.V.',
          categories: ['web-development', 'administration'],
        },
        {
          src: '/assets/img/projects/wedding.jpg',
          title: 'Krohn\'s Wedding',
          text: 'Diese Web-Applikation war zusammen mit den darüber vefügbaren Videos das Hochzeitsgeschenk für meine Schwester und meinen Schwager und erlaubte es Fotos der Hochzeit darüber zu teilen.',
          start: '05.07.2014',
          client: 'Privat',
          categories: ['web-development', 'MySQL', 'javascript'],
        },
        {
          src: '/assets/img/projects/konzentrationstest.jpg',
          title: 'Polizei Konzentrationstest',
          text: 'http://shabbtech.com/konzentrationstest/v2/',
          start: '05.07.2010',
          client: 'Privat',
          categories: ['javascript', 'PHP', 'MySQL'],
        },
      ],
    },
    clients: {
      title: 'What my clients say',
      cites: [
        {
          name: 'CAT Europe',
          text: 'Alles super! Ich hoffe weiterhin auf gute Kooperation.',
          year: '2019',
          img: '',
        },
        {
          name: 'SCN e.V.',
          text: 'Die Arbeit mit Sascha macht wirklich Spaß genauso wie die Qualität der Ergebnisse.',
          img: '',
          year: '2019',
        },
      ],
    },
    contact: {
      title: 'Let\'s talk',
      what_can_i_do: {
        headline: 'What can I do for you?',
//         text    : `Haben Sie eine Idee? Haben Sie Fragen?<br/>
// Schreiben Sie mir, wenn ich ihnen irgendwie behilflich sein kann.
// Achja, Ich bin Sascha und das "Du" gehört bei mir zum guten Ton.
// In diesem Sinne, freue mich darauf von dir hören und dich bei deinem Vorhaben zu unterstüzen.`,
        text: `You have an idea? you have questions?<br/>
Write me about it in case I can help you.<br/>
I would be happy to hear from you and assist you with your Vision.`,
      },
      send_me_a_msg: 'Send me a message.',
      form: {
        name: {
          label: 'Your Name',
          hint: 'Hey {name}',
        },
        email: {
          label: 'Your email address',
          hint: 'So that I can answer you.',
        },
        subject: {
          label: 'Subject',
          hint: 'What is it about?',
        },
        msg: {
          label: 'Your message',
          hint: 'How may I help you?',
        },
      },
    },
  },
};
