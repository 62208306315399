
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import LoadingIndicator from '~/components/common/loading-indicator.vue';
import AppFooter from '~/components/layout/app-footer.vue';
import AppHeader from '~/components/layout/app-header.vue';
import ContentNavigation from '~/components/layout/content-navigation.vue';

@Component({
  components: {AppHeader, AppFooter, LoadingIndicator, ContentNavigation},
})
export default class ContentLayout extends Vue {
  get pageWrapStyle() {
    return {
      minHeight: 'calc(100% - 36px)',
    };
  }
}
