import moment from 'moment';
import {Module} from 'vuex';
import {RootState} from '~/store/index';

const resume = {
  basics: {
    name: 'Sascha Bialon',
    label: 'Software Engineer',
    birthday: '1985-08-13',
    skype: 'sh4bby',
    picture: '',
    email: 'sascha.bialon@shabbtech.com',
    phone: '(+49) 176 62121240',
    phoneHref: 'tel:+49 176 62121240',
    website: 'https://www.shabbtech.com',
    summary: 'A summary of Sascha Bialon...',
    location: {
      address: 'Bäckerweg 46',
      postalCode: '64546',
      city: 'Mörfelden-Walldorf',
      countryCode: 'DE',
      country: 'Germany',
      region: 'Frankfurt am Main',
    },
    profiles: [{
      network: 'Twitter',
      username: 'john',
      url: 'http://twitter.com/john',
    }],
  },
  work: [
    {
      company: 'Mks-InfoFabrik',
      position: 'Software Developer (in part time)',
      website: 'https://infofabrik.de',
      startDate: '2008-01-01',
      endDate: '2011-01-01',
      summary: 'Description...',
      highlights: [
        'Started the company',
      ],
    },
    {
      company: 'CASED',
      position: 'Student Research Assistant',
      website: 'https://www.cysec.tu-darmstadt.de/our_research/collaborative_projects/cased.de.jsp',
      startDate: '2011-01-01',
      endDate: '2011-01-01',
      summary: 'Description...',
      highlights: [
        'Started the company',
      ],
    },
    {
      company: 'Ignition Groupe GmbH',
      position: 'Frontend Developer',
      website: 'http://www.ignitiongroupe.com/',
      startDate: '2011-01-01',
      endDate: '2013-01-01',
      summary: 'Description...',
      highlights: [
        'Started the company',
      ],
    },
    {
      company: 'Factset Digital Solutions GmbH',
      position: 'Senior Developer',
      website: 'http://www.ignitiongroupe.com/',
      startDate: '2015-01-01',
      endDate: '2019-01-01',
      summary: 'Description...',
      highlights: [
        'Started the company',
      ],
    },
  ],
  volunteer: [{
    organization: 'Organization',
    position: 'Volunteer',
    website: 'http://organization.com/',
    startDate: '2012-01-01',
    endDate: '2013-01-01',
    summary: 'Description...',
    highlights: [
      'Awarded \'Volunteer of the Month\'',
    ],
  }],
  education: [{
    institution: 'University',
    area: 'Software Development',
    studyType: 'Bachelor',
    startDate: '2011-01-01',
    endDate: '2013-01-01',
    gpa: '4.0',
    courses: [
      'DB1101 - Basic SQL',
    ],
  }],
  awards: [{
    title: 'Award',
    date: '2014-11-01',
    awarder: 'Company',
    summary: 'There is no spoon.',
  }],
  publications: [{
    name: 'Publication',
    publisher: 'Company',
    releaseDate: '2014-10-01',
    website: 'http://publication.com',
    summary: 'Description...',
  }],
  skills: [{
    name: 'Web Development',
    level: 'Master',
    keywords: [
      'HTML',
      'CSS',
      'Javascript',
    ],
  }],
  languages: [{
    language: 'English',
    fluency: 'Native speaker',
  }],
  interests: [{
    name: 'Wildlife',
    keywords: [
      'Ferrets',
      'Unicorns',
    ],
  }],
  references: [{
    name: 'Jane Doe',
    reference: 'Reference...',
  }],
};

export const resumeStore: Module<typeof resume, RootState> = {
  namespaced: true,
  state: resume,
  mutations: {},
  actions: {},
  getters: {
    age(state) {
      return moment.duration(moment().diff(state.basics.birthday)).get('years');
    },
    experienceInYears() {
      return moment.duration(moment().diff('2008-08-01')).get('years');
    },
  },
};
