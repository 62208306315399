
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class SocialContacts extends Vue {
  @Prop({type: Boolean, default: false}) bottom: boolean;

  onTwitterClick() {
    window.open('https://twitter.com/ShabbTech');
  }

  onXingClick() {
    window.open('https://www.xing.com/profile/Sascha_Bialon/');
  }

  onLinkedInClick() {
    window.open('https://www.linkedin.com/in/sascha-bialon-580409a9/');
  }

  onGithubClick() {
    window.open('https://github.com/sh4bby');
  }

  onStackOverflowClick() {
    window.open('https://stackoverflow.com/users/4243683/shabby');
  }
}
