export const de = {
  p: {
    image: 'Bild | Bilder',
    user: 'Benutzer | Benutzer',
    comment: 'Kommentar | Kommentare',
  },
  label: {
    website: 'Webseite',
    username: 'Benutzername',
    profile: 'Profil',
    home: 'Home',
    imprint: 'Impressum',
    email: 'Email',
    password: 'Passwort',
    passwordConfirmation: 'Passwort wiederholen',
    administration: 'Administration',

    testimonial: 'Zeugnis',
    location: 'Standort',
    address: 'Adresse',
    phone: 'Telefon',
    skype: 'Skype',
    goLive: 'Livegang',
    tags: 'Tags',
    client: 'Kunde',
    yourName: 'Dein Name',
    yourEmail: 'Deine Email Addresse',
    subject: 'Betreff',
    yourMessage: 'Deine Nachricht',
  },
  navigation: {
    about: 'Über Mich',
    experience: 'Erfahrungen',
    skills: 'Fähigkeiten',
    portfolio: 'Portfolio',
    clients: 'Kunden',
    contact: 'Kontakt',
  },
  action: {
    writeMeAnEmail: 'Schreib mir ne Email',
    login: 'Anmelden',
    logout: 'Abmelden',
    cancel: 'Abbrechen',
    selectItem: '{item} auswählen',
    editItem: '{item} bearbeiten',
    updateItem: '{item} aktualisieren',
    send: 'Absenden',
    showAll: 'Alle Anzeigen',
  },
  notify: {
    info: 'Info',
    error: 'Error',
    success: 'Erfolg',
    serverError: 'Server Error',
    clientError: 'Client Error',
    notFound: 'Nicht Gefunden',
    addItemSuccess: '{item} wurde hinzugefügt',
    createItemSuccess: '{item} wurde erstellt',
    deleteItemSuccess: '{item} wurde gelöscht',
    unauthorized: 'Nicht Authorisiert',
    unknownError: 'Unbekannter Error',
  },
  msg: {},
  error: {
    pageNotFound: 'Die Seite "{page}" existiert nicht.',
    accessForbidden: 'Zugang Verboten.',
  },


  screen: {
    about: {
      who_i_am: {
        title: 'Wer Ich bin',
        text: `
Hallo, mein Name ist Sascha und ich bin freiberuflicher Software Entwickler aus der Rhein-Main Region.
Ich bin {age} Jahre alt und seit über {experienceInYears} Jahren in der Software Entwicklung tätig.
Im Zuge der Digitalisierung Ihrer Geschäftsprozesse, unterstütze ich Sie vom ersten konzeptionellen Entwurf ihrer Idee bis hin zum fertigen Produkt. Frontend- und Backend-Entwicklung aus einer Hand.
Smarte Software Architekturen, moderne Frameworks, Clean Code, Test Driven Development, Domain Driven Design.
Ich bin vertraut mit den Praktiken der agilen Softwareentwicklung und begeistert von Extreme Programming.
Unit-, Integrations- oder Acceptance-Tests werden bei Bedarf gerne abgedeckt. Continuous Integration Pipelines stellen sicher, dass alle Tests erfolgreich durchlaufen, bevor die Software eingesetzt wird.`,
      },
      what_i_do: {
        headline: 'Was ich mache',
        text: `In einfachen Worten gesagt entwickele ich Web Applikationen. Also Software die Sie über den Browser ihres PCs oder Mobilgeräts nutzen können.<br/>
          Dadurch kann ich ihnen ein starker Partner für die <b>digitale Transformation</b> ihres Unternehmens sein. 
          Ich arbeite agil und transparent daran ihre Vorstellungen effektiv umzusetzen und liefere in kurzen Abständen Resultate die sie direkt ausprobieren können.
          Mit ihrem Feedback und der Priorisierung der nächsten Aufgaben, nehmen Sie aktiv an dem Entstehungsprozess und der Gestaltung ihrer Vision teil.<br/>`,
      },
      how_i_do_it: {
        headline: 'Wie ich arbeite',
        text: `
<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid dolore doloremque eaque enim est et eveniet exercitationem facilis, illum ipsam labore officia omnis quia quos reprehenderit sapiente sed, totam?</p>`,
      },
      what_i_can_do_for_you: {
        headline: 'Was ich für dich tun kann',
        services: [
          {
            title: 'Frontend Entwicklung',
            text: 'Ich habe mich spezialisiert auf moderne web-applications und arbeite mit den neusten Technologien daran,' +
              'ihre Ideen in die Realität zu überführen. Dabei setze ich hauptsächlich auf javascript bzw typescript.' +
              'Im Backend arbeite ich primär mit Node.js und NestJS. Im Frontend baue ich auf Vue.Js und VuetifyJs.' +
              'Damit schaffe ich Web-Applikationen die auch mobile ansprechend sind und sich einfach bedienen lassen.',
          },
          {
            title: 'Backend Entwicklung',
            text: 'Web Services ; Sie brauchen keine Web-Applikation sodern nur eine API, die ihre bestehenden Services erweitern soll?' +
              'Kein Problem, sagen sie mir welche funktionen sie benötigen und ich liefere ihnen eine REST API',
          },
          {
            title: 'DevOps',
            text: 'Brauchen sie unterstützen bei ihrem',
          },
          {
            title: 'Administration',
            text: 'lorem...',
          },
          {
            title: 'Beratung / Unterstützung',
            text: `Brauchen sie Hilfe eines erfahrenen Software Engineers in ihrem Projekt?
              Sei es in form von Beratung, Konzeption oder Umsetzung, freue ich mich darauf sie tatkräftig zu unterstützen.`,
          },
        ],
      },
    },
    experience: {
      title: 'Wo Ich Erfahrungen sammeln konnte',
    },
    skills: {
      title: 'Womit Ich mich auskenne',
      text: `Hier findest du eine unvollständige Auflistung der Sprachen, Werkzeuge, Libraries und Frameworks, mit denen ich arbeite.`,
      techstack: {
        javascript: 'Javascript bzw. EcmaScript ist ein Grundpfeiler meines Technologie-Stacks. Seit meiner Anstellung als Webdeveloper in 2011 liegt mein Fokus auf Javascript.',
        typescript: 'Typescript ist ein Superset von JavaScript und bietet neben der Typ-Sicherheit noch weitere Benefits, welche die Wartung einer Application vereinfachen. Meint erste Kontakt mit Typescript war 2016. Seitdem arbeite ich primär mit TypeScript',
        nodejs: 'Node.js ist eine plattformübergreifende Open-Source-JavaScript-Laufzeitumgebung, die JavaScript-Code außerhalb eines Webbrowsers ausführen kann. So kann man auf Basis von NodeJs, Server- oder Desktop- Applikationen entwickeln und betreiben.',
        html5: 'HTML5 ist die fünfte Fassung der Hypertext Markup Language, einer Computersprache zur Auszeichnung und Vernetzung von Texten und anderen Inhalten elektronischer Dokumente.',
        vuejs: 'Vue.js ist ein clientseitiges JavaScript-Webframework zum Erstellen von Single-Page-Webanwendungen nach dem MVVM-Muster, es kann allerdings auch in Multipage-Webseiten für einzelne Abschnitte verwendet werden. Ab Version 2.0 unterstützt es auch serverseitiges Rendern.',
        angular: 'Angular auch „Angular 2+“ ist ein TypeScript-basiertes Front-End-Webapplikationsframework. Es wird von einer Community aus Einzelpersonen und Unternehmen, angeführt durch Google, entwickelt und als Open-Source-Software publiziert.',
        php: 'PHP ist eine Skriptsprache mit einer an C und Perl angelehnten Syntax, die hauptsächlich zur Erstellung dynamischer Webseiten oder Webanwendungen verwendet wird. PHP zeichnet sich durch breite Datenbankunterstützung und Internet-Protokolleinbindung sowie die Verfügbarkeit zahlreicher Funktionsbibliotheken aus.',
        docker: 'Docker ist eine Freie Software zur Isolierung von Anwendungen mit Hilfe von Containervirtualisierung. Docker vereinfacht die Bereitstellung von Anwendungen, weil sich Container, die alle nötigen Pakete enthalten, leicht als Dateien transportieren und installieren lassen. Container gewährleisten die Trennung und Verwaltung der auf einem Rechner genutzten Ressourcen.',
        jenkins: 'Jenkins ist ein erweiterbares, webbasiertes Software-System zur kontinuierlichen Integration von Software-Komponenten zu einem Anwendungsprogramm. Es wird als Fork der Software Hudson von Sun, heute Oracle, betrachtet.',
        nginx: 'Nginx ist eine von Igor Sysoev entwickelte, unter der BSD-Lizenz veröffentlichte Webserver-Software, Reverse Proxy und E-Mail-Proxy',
        amazonwebservices: 'Amazon Web Services (AWS) ist ein US-amerikanischer Cloud-Computing-Anbieter, der 2006 als Tochterunternehmen des Online-Versandhändlers Amazon.com gegründet wurde. Zahlreiche populäre Dienste wie beispielsweise Dropbox, Netflix, Foursquare oder Reddit greifen auf die Dienste von Amazon Web Services zurück',
        git: 'Git ist eine freie Software zur verteilten Versionsverwaltung von Dateien, die durch Linus Torvalds initiiert wurde.',
        github: 'GitHub ist ein netzbasierter Dienst zur Versionsverwaltung für Software-Entwicklungsprojekte. Namensgebend war das Versionsverwaltungssystem Git. Das Unternehmen GitHub, Inc. hat seinen Sitz in San Francisco in den USA.',
        babel: 'Babel ist ein Javascript kompiler der neuste Javascript Sprachfeatures durch polyfills in älteren Browsern ermöglicht',
        css3: 'Cascading Style Sheets ist eine Stylesheet-Sprache für elektronische Dokumente und zusammen mit HTML und JavaScript eine der Kernsprachen des World Wide Webs. Sie ist ein sogenannter living standard und wird vom World Wide Web Consortium (W3C) beständig weiterentwickelt.',
        sass: 'Sass (Syntactically Awesome Stylesheets) ist eine Stylesheet-Sprache, die als CSS-Präprozessor mit Variablen, Schleifen und vielen anderen Funktionen, die Cascading Style Sheets (CSS) nicht beinhaltet, die Erstellung von CSS vereinfacht und die Pflege großer Stylesheets erleichtert.',
        mongodb: 'MongoDB ist ein dokumentenorientiertes NoSQL-Datenbankmanagementsystem, das in der Programmiersprache C++ geschrieben ist. Sie kann Sammlungen von JSON-ähnlichen Dokumenten verwalten. So können viele Anwendungen Daten auf natürlichere Weise modellieren, da die Daten zwar in komplexen Hierarchien verschachtelt werden können, dabei aber immer abfragbar und indizierbar bleiben.',
        webpack: 'Webpack ist ein Opensource-JavaScript-Modul-Packer. Die wesentliche Aufgabe besteht darin, JavaScript-Dateien für die Nutzung im Browser zusammenzuführen und zu einer Datei zu bündeln; Webpack ist allerdings auch in der Lage, andere Ressourcen zu transformieren, zu bündeln und zu packen.',
        yarn: 'Yarn ist ein Software Paketmanager der 2016 von Facebook für Node.js entwickelt wurde. Es ist eine Alternative zum NPM Paketmanager. Yarn wurde in Kooperation von Facebook, Exponent, Google, und Tilde geschaffen um Konsistenz-, Sicherheits-, und Performance-Probleme zu beheben',
        jest: 'Jest ist ein JavaScript Testing Framework mit Fokus auf einfachheit.',
        composer: 'Composer ist ein anwendungsorientierter Paketmanager für die Skriptsprache PHP',
        symfony: 'Symfony ist ein in PHP geschriebenes Webframework und eine Menge von wiederverwendbaren PHP-Komponenten/Bibliotheken.',
        laravel: 'Laravel ist ein freies PHP-Webframework, das dem MVC-Muster folgt. Es wurde 2011 von Taylor Otwell initiiert.',
        java: 'Java ist eine objektorientierte Programmiersprache. Sie besteht grundsätzlich aus dem Java-Entwicklungswerkzeug (JDK) zum Erstellen von Java-Programmen und der Java-Laufzeitumgebung (JRE) zu deren Ausführung.',
        kotlin: 'Kotlin ist eine plattformübergreifende, statisch typisierte Programmiersprache, die in Bytecode für die Java Virtual Machine (JVM) übersetzt wird, aber auch in JavaScript-Quellcode oder (mittels LLVM) in Maschinencode umgewandelt werden kann. Kotlin lässt sich außerdem zur Entwicklung von Android- und iOS-Apps verwenden.',
        apache: 'Der Apache HTTP Server ist ein quelloffenes und freies Produkt der Apache Software Foundation und einer der meistbenutzten Webserver im Internet. Neben Faktoren wie Leistungsfähigkeit, Erweiterbarkeit, Sicherheit, Lizenzkosten-freiheit und dem Support durch eine sehr große Community ist seine langjährige Verfügbarkeit für unterschiedlichste Betriebssysteme einer der Gründe für seine hohe Verbreitung.',
        digitalocean: 'DigitalOcean ist ein Amerikanischer Cloud-Infrastrukturanbieter mit Sitz in New York City und Datenzentren auf der ganzen Welt. DigitalOcean bietet Entwicklern, Start-Ups, und kleineren Unternehmen Infrastructure-as-a-service Platformen.',
        bash: 'Bash, die Bourne-again shell, ist eine freie Unix-Shell. Als Shell ist Bash eine Mensch-Maschine-Schnittstelle, die eine Umgebung bereitstellt, in der zeilenweise Texteingaben und -ausgaben möglich sind.',
        redis: 'Redis ist eine In-Memory-Datenbank mit einer einfachen Schlüssel-Werte-Datenstruktur und gehört zur Familie der NoSQL-Datenbanken. Redis ist Open Source und laut einer Erhebung von DB-Engines.com der verbreitetste Schlüssel-Werte-Speicher.',
        nestjs: 'Nest.js ist eines der am schnellsten wachsenden Node.js-Frameworks für den Aufbau effizienter, skalierbarer und unternehmenstauglicher Backend-Anwendungen mit Node.js. Es ist dafür bekannt, dass es mit modernem JavaScript und TypeScript hochgradig testbare, wartbare und skalierbare Anwendungen erstellt.',
        mysql: 'MySQL ist eines der weltweit verbreitetsten relationalen Datenbankverwaltungssysteme. Es ist als Open-Source-Software sowie als kommerzielle Enterpriseversion für verschiedene Betriebssysteme verfügbar und bildet die Grundlage für viele dynamische Webauftritte.',
        kubernetes: 'Kubernetes ist ein von Google entwickeltes Open-Source-System zur Verwaltung von Container–Anwendungen. In diesem Umfeld wird die Verwaltung der technischen Container–Infrastruktur auch als Orchestrierung bezeichnet.',
      },
    },
    portfolio: {
      title: 'Meine Projekte',
    },
    clients: {
      title: 'Was meine Kunden sagen',
    },
    contact: {
      title: 'Lass uns reden',
      whatCanIDo: {
        headline: 'Was kann Ich für Dich tun?',
        text: `Schreib mich einfach an, wenn ich etwas für Dich tun kann.<br/>Ich freue mich von Dir zu hören!`,
      },
    },
  },

  hint: {
    subject: 'Worum geht es?',
    message: 'Was kann ich für Dich tun?',
  },
};
