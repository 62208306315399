// import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css'
import Vue from 'vue';
import Vuetify from 'vuetify';
import de from 'vuetify/src/locale/de';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify);

export const vuetify = new Vuetify({
  treeShake: true,
  lang: {
    locales: {de, en},
    current: 'de',
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#FFAA00',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      light: {
        primary: '#FFAA00',
        secondary: '#2196F3',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
});
